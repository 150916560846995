<template>
  <div id="overlay" v-if="overlay"></div>

  <div>
    <Toast/>

    <div class="card">
      <Steps :model="items" :readonly="true"/>
    </div>

    <Card>
      <template #header>

      </template>
      <template #title>
        Seleccionar Fecha
      </template>
      <template #content>
        <div class="margin-40">
        <!-- Fecha -->
          <Calendar v-model="selectedDate"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                    class="filterDate"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :showOtherMonths="true"
                    :selectOtherMonths="true"
          />
        </div>

      </template>
      <template #footer>

        <div class="grid grid-nogutter justify-content-end">
          <Button label="Siguiente"
                  @click="nextPage()"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  :disabled="this.selectedDate ? disabled : ''"
          />

        </div>
      </template>
    </Card>

    <router-view v-slot="{Component}" :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)"
                 @complete="complete">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>


  </div>
</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import moment from "moment-timezone";

export default {
  name: "Step1",
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService();
  },
  mounted() {

  },
  data() {
    return {
      items: [
        {
          label: 'Seleccionar Fecha',
          to: '/tickets-buscar-1'
        },
        {
          label: 'Seleccionar Agencia',
          to: '/tickets-buscar-2'
        },
        {
          label: 'Seleccionar Ticket',
          to: '/tickets-buscar-3'
        }
      ],
      loadingAgencia: false,
      selectedAgencia: null,
      agencias: null,
      pageIndex: 0,
      selectedDate: moment().locale('es').toDate(),
      maxDate: moment().locale('es').toDate(),
      minDate: null,
      overlay: false,
    }
  },
  methods: {
    nextPage() {
      this.overlay = true;
      this.$router.push({
        name: 'tickets-buscar-2', query: {
          fecha: this.convertDate(this.selectedDate),
          game: this.$router.currentRoute.value.params.game,
        }
      })
    },

    prevPage() {
      this.$router.push('/tickets-buscar-1');
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.agencia {
  width: 100% !important;
  font-size: large;
}

.filterDate {
  width: 100%;
  margin-bottom: 10px;

}
</style>